import React, { Fragment } from 'react';
import styles from './disclaimer-footer.module.scss'; // eslint-disable-line no-redeclare
import cx from '../../../utils/classnames';
import { DangerousHtml } from '../../common/dangerous-html/dangerous-html';
export var renderFootnote = function (id, footies) { return "".concat(id, "<br/>").concat(footies[id]); };
var DisclaimerFooter = function (_a) {
    var ids = _a.ids, footnotes = _a.footnotes, final = _a.final, noPadding = _a.noPadding;
    return (React.createElement("div", { className: noPadding ? styles.disclaimerMainNoPadding : styles.disclaimerMain },
        ids.map(function (id, i) {
            var _a;
            return (React.createElement(Fragment, { key: "disc".concat(i) },
                React.createElement("div", { className: styles.disclaimer },
                    id.replace('_', '') && (React.createElement(React.Fragment, null,
                        React.createElement("sup", null, id.replace('_', '')),
                        "\u00A0")),
                    React.createElement(DangerousHtml, { className: cx((_a = {}, _a[styles.bold] = footnotes[id + '_disclaimer'], _a)), html: footnotes[id] })),
                footnotes[id + '_disclaimer'] && (React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement(DangerousHtml, { html: footnotes[id + '_disclaimer'] }),
                        React.createElement("br", null))))));
        }),
        final && (React.createElement("div", { className: styles.disclaimer },
            React.createElement(DangerousHtml, { html: final })))));
};
export default DisclaimerFooter;
