import React, { useEffect, useRef, useState } from 'react';
import cx from '../../../utils/classnames';
import styles from './tabbar.module.scss';
import utils from '../../../utils/utils.module.scss';
import { FdsChevron } from '../chevron/fds-chevron';
function TabBar(_a) {
    var _b;
    var _c, _d;
    var children = _a.children, active = _a.active, setTab = _a.setTab, className = _a.className, ariaLabel = _a.ariaLabel, mobileTitle = _a.mobileTitle, _e = _a.forceTabs, forceTabs = _e === void 0 ? false : _e;
    var _f = useState(false), open = _f[0], setOpen = _f[1];
    var theButton = useRef();
    useEffect(function () {
        if (open) {
            document.addEventListener('mousedown', doClose);
        }
        else {
            document.removeEventListener('mousedown', doClose);
        }
        return function () { return document.removeEventListener('mousedown', doClose); };
    }, [open]);
    var doClose = function () {
        setOpen(false);
        setTimeout(function () { var _a; return (_a = theButton === null || theButton === void 0 ? void 0 : theButton.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 10);
    };
    var renderTabbar = function () { return (React.createElement("div", { className: cx(styles.container, className), role: 'tablist', onMouseDown: doClose }, children
        .filter(function (t) { return t; })
        .map(function (tab) {
        return React.cloneElement(tab, {
            key: tab.props.id,
            setTab: setTab,
            active: active === tab.props.id,
        });
    }))); };
    if (forceTabs)
        return renderTabbar();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: utils.ShowDesktop }, renderTabbar()),
        React.createElement("div", { className: utils.ShowMobile },
            React.createElement("button", { className: cx(styles.openButton, className, 'openButton', (_b = {}, _b[styles.open] = open, _b)), onMouseDown: function (e) {
                    e.preventDefault();
                    if (open)
                        doClose();
                    else
                        setOpen(true);
                }, onKeyDown: function (e) {
                    if (e.code === 'Enter') {
                        e.preventDefault();
                        if (open)
                            doClose();
                        else
                            setOpen(true);
                    }
                }, ref: theButton, id: ariaLabel },
                mobileTitle ? mobileTitle : (_d = (_c = children.filter(function (t) { return t; }).find(function (t) { return t.props.id == active; })) === null || _c === void 0 ? void 0 : _c.props) === null || _d === void 0 ? void 0 : _d.children,
                React.createElement(FdsChevron, { direction: open ? 'up' : 'down' })),
            open && renderTabbar())));
}
export default TabBar;
