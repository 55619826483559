import React from 'react';
import styles from './tiers-visa-cards.module.scss';
import { useContent } from '../../../hooks/use-content';
//////////////////////
///////////////////////////////////////////////////////////////////////////
/////////
import { footies } from '../../../views-common/footnotes/footnotes-fpr.js';
//////////
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import PopupIcon, { DIRECTION } from '../../common/popup-icons/popup-icons';
import { isMobile } from '../../../services/mobile-bridge/mobile-bridge';
import { renderFootnote } from '../disclaimer-footer/disclaimer-footer';
import { DangerousHtml } from '../../common/dangerous-html/dangerous-html';
var TiersVisaCards = function () {
    var _a = useContent('v2/program-simplification-page')[0], content = _a.content, getValueByTitle = _a.getValueByTitle, getHtmlByTitle = _a.getHtmlByTitle;
    var footnotes = {
        e: footies.TIER_LIMITATIONS,
    };
    return content ? (React.createElement("div", { className: styles.cardContainer },
        React.createElement("div", { className: styles.leftContainer },
            React.createElement("div", { className: styles.leftCard }, getValueByTitle('advance-your-tier-card1')),
            React.createElement("div", { className: styles.silverTier },
                getValueByTitle('automatic-silver1'),
                React.createElement(PopupIcon, { letter: 'e', desktopDirection: DIRECTION.RIGHT, id: '', className: styles.visaSupLetter },
                    React.createElement(DangerousHtml, { html: isMobile() ? getHtmlByTitle('disclaimers') : renderFootnote('e', footnotes) })),
                React.createElement("br", null),
                getValueByTitle('automatic-silver2'))),
        React.createElement("div", { className: styles.rightContainer },
            React.createElement("div", { className: styles.rightCard }, getValueByTitle('advance-your-tier-card2')),
            React.createElement("div", { className: styles.blueTier },
                getValueByTitle('automatic-blue1'),
                React.createElement(PopupIcon, { letter: 'e', desktopDirection: DIRECTION.RIGHT, id: '', className: styles.visaSupLetter },
                    React.createElement(DangerousHtml, { html: isMobile() ? getHtmlByTitle('disclaimers') : renderFootnote('e', footnotes) })),
                React.createElement("br", null),
                getValueByTitle('automatic-blue2'))))) : (React.createElement(ActivityIndicator, null));
};
export default TiersVisaCards;
