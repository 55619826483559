export const footies = {
  APR: 'For additional information about Annual Percentage Rates (APRs), fees, and other costs, see the {MAGIC_SOCT_URL}.',
  APR_CARD: 'For additional information about Annual Percentage Rates (APRs), fees, and other costs, see the Summary of Credit Terms.',
  OFFERS_VARY:
    'Offers may vary depending on where you apply, for example online or in person. To take advantage of this offer, apply now directly through this advertisement. Review offer details before you apply.',
  OFFERS_VARY_SUB:
    'Please see the {MAGIC_SOCT_URL} for important information on rates, fees, costs, conditions, and limitations. Minimum monthly payments required.<br/><br/><b>Please note you must pay your entire statement balance (with the exception of purchases made with this promotional rate during its promotional rate period) in full by the payment due date each month to avoid being charged interest on new non-promotional purchases from the date those purchases are made.</b><br/><br/>Special Financing Offer Details: Purchases in which single or multiple items are purchased in the same transaction totaling at least $499.00 at Ford Dealerships will be referred to as “Eligible Purchases” in your {MAGIC_SOCT_URL}. All Transactions are subject to approval. Accounts are elibile only if they are open, in good standing and not already subject to special terms. All other terms of your account remain in effect. Minimum Monthly Payments required.',
  CARDHOLDER_DISCLAIMERS:
    'Please see your Summary of Credit Terms for important information on rates, fees, costs, conditions, and limitations. <br/><br/>We reserve the right to change your account terms for reasons described in your Cardmember Agreement. <br/><br/><b>Please note you must pay your entire statement balance (with the exception of purchases made with this promotional rate during its promotional rate period) in full by the payment due date each month to avoid being charged interest on new non-promotional purchases from the date those purchases are made.</b>',
  TandC:
    "See the FordPass® Rewards Visa® terms and conditions in the {MAGIC_SOCT_URL}. Points are not redeemable for cash or check, and have no monetary value. Please see the FordPass® Rewards Program <a href='https://www.fordpass.com/content/ford_com/fp_app/en_us/termsprivacy.html'>terms and conditions</a> for information regarding expiration, redemption, forfeiture and other limitations on FordPass® Rewards Points. Must have an activated FordPass Rewards account to receive FordPass Rewards Points.",
  TandC_CARD:
    "See the FordPass® Rewards Visa® terms and conditions in the Summary of Credit Terms. Points are not redeemable for cash or check, and have no monetary value. Please see the FordPass® Rewards Program <a href='https://www.fordpass.com/content/ford_com/fp_app/en_us/termsprivacy.html'>terms and conditions</a> for information regarding expiration, redemption, forfeiture and other limitations on FordPass® Rewards Points. Must have an activated FordPass Rewards account to receive FordPass Rewards Points.",
  TIER_LIMITATIONS:
    "Tier Activity limitations apply. Must have an activated FordPass® Rewards account within 60 days of qualifying activity to receive Tier Activity. See <a href='/faqs'>FAQs</a> for details.",
  OTHER_LIMITATIONS:
    'Limitations apply. Earn Points for the purchase of Ford, Motorcraft®, or Omnicraft® parts and associated labor at a participating Ford Dealership. See FordPass® Rewards Terms and FAQs. Must have an activated FordPass Rewards account within 60 days of service invoice date to receive FordPass Rewards Points.',
  MODEM1:
    'Within 60 days of vehicle sale, must activate your modem through the FordPass<sup>®</sup> App and activate your FordPass<sup>®</sup> Rewards account to receive Points and one Tier Activity. Visit a participating dealer for details.',
  MODEM2:
    'Modem must be activated within 60 days of purchase through the FordPass<sup>®</sup> App and remain active for at least 6 months after activation to receive Points and a Tier Activity. BlueOval™ Charge Network must be activated to receive 250 kWh of complimentary charging at Electrify America<sup>®</sup>. Complimentary charging expires 3 years from warranty start date. Offer excludes idle fees associated with charging. See dealer for important details. Must be enrolled in FordPass Rewards.',
  ACTIVATE_ACCOUNT:
    'Within 60 days of vehicle sale, must activate your FordPass<sup>®</sup> Rewards account to receive 22,000 Points (Gold Certified) or 11,000 Points (Blue Certified) and one Tier Activity. Modem not required. Visit a participating dealer for details.',
  BLUE_ADVANTAGE:
    'Buyers of Ford Blue Advantage<sup>®</sup> vehicles will receive 22,000 Points (Gold Certified) or 11,000 Points (Blue Certified) and a Tier Activity after enrollment within 60 days of vehicle sale. Modem not required. Visit a participating dealer for details. Must be enrolled in FordPass Rewards.',
  FORDPASS_VISA:
    'See the FordPass<sup>®</sup> Rewards Visa<sup>®</sup> terms and conditions in the {MAGIC_SOCT_URL}. Points are not redeemable for cash or check, and have no monetary value. Please see the FordPass<sup>®</sup> Rewards Program <a href="https://www.fordpass.com/content/ford_com/fp_app/en_us/termsprivacy.html">terms and conditions</a> for information regarding expiration, redemption, forfeiture and other limitations on FordPass<sup>®</sup> Rewards Points. Must be enrolled in FordPass Rewards.',
  SHELL_DISCLAIMER:
    'Link your Silver or Blue Tier FordPass<sup>®</sup> Rewards and your Fuel Rewards<sup>®</sup> accounts to save 5¢/gal<sup>*</sup> on your fill-ups at participating Shell stations.',
  SHELL_DISCLAIMER_GOLDSTAR:
    '<sup>*</sup>Restrictions apply. Offer valid from January 13, 2025 through January 12, 2026 (the "Offer Period"). Receive ongoing Fuel Rewards<sup>®</sup> Complimentary Gold Status (the “Gold Status Reward”) when you successfully link your Fuel Rewards<sup>®</sup> account to your FordPass Rewards account. To qualify for this offer, you must (i) register either as a new Fuel Rewards<sup>®</sup> member or log in with your existing Fuel Rewards<sup>®</sup> member information and (ii) successfully link your Silver or Blue Tier FordPass Rewards account to your Fuel Rewards<sup>®</sup> account at <a href="https://join.fuelrewards.com/frconnect/registration.html?partSrcId=fordpass">https://join.fuelrewards.com/frconnect/registration.html?partSrcId=fordpass</a> during the Offer Period. Upon successful completion of the above, you will receive 5¢/gal in Fuel Rewards<sup>®</sup> savings on up to 20 gallons on your fill-ups at participating Shell stations during the Offer Period. To redeem the Gold Status Reward, swipe your Fuel Rewards<sup>®</sup> card, a linked payment card or enter your Alt ID at a participating Shell station and complete your fill-up. Gold Status Rewards offered to FordPass Rewards members cannot be combined with tier status rewards from any other Fuel Rewards<sup>®</sup> program offer but can be combined with other rewards in your Fuel Rewards<sup>®</sup> account. It may not be possible to combine multiple discounts and/or rewards in a single transaction. Visit <a href="https://www.fuelrewards.com/status">fuelrewards.com/status</a> for full tier status terms.',
  SHELL_DISCLAIMER_GOLD:
    'Restrictions apply. Offer valid from January 13, 2025 through January 12, 2026 (the "Offer Period"). Receive ongoing Fuel Rewards<sup>®</sup> Complimentary Gold Status (the “Gold Status Reward”) when you successfully link your Fuel Rewards<sup>®</sup> account to your FordPass Rewards account. To qualify for this offer, you must (i) register either as a new Fuel Rewards<sup>®</sup> member or log in with your existing Fuel Rewards<sup>®</sup> member information and (ii) successfully link your Silver or Blue Tier FordPass Rewards account to your Fuel Rewards<sup>®</sup> account at <a href="https://join.fuelrewards.com/frconnect/registration.html?partSrcId=fordpass">https://join.fuelrewards.com/frconnect/registration.html?partSrcId=fordpass</a> during the Offer Period. Upon successful completion of the above, you will receive 5¢/gal in Fuel Rewards<sup>®</sup> savings on up to 20 gallons on your fill-ups at participating Shell stations during the Offer Period. To redeem the Gold Status Reward, swipe your Fuel Rewards<sup>®</sup> card, a linked payment card or enter your Alt ID at a participating Shell station and complete your fill-up. Gold Status Rewards offered to FordPass Rewards members cannot be combined with tier status rewards from any other Fuel Rewards<sup>®</sup> program offer but can be combined with other rewards in your Fuel Rewards<sup>®</sup> account. It may not be possible to combine multiple discounts and/or rewards in a single transaction. Visit <a href="https://www.fuelrewards.com/status">fuelrewards.com/status</a> for full tier status terms.',
  SHELL_DISCLAIMER_GOLD_2:
    'Void where prohibited, only available in US. Fuel Rewards<sup>®</sup> savings are limited to 20 gallons of fuel per purchase per vehicle or fraud limits placed by Shell and/or limits placed on your payment card by your financial institution, each of which may be lower. Dispenser may require a price of up to $0.109/gallon. Once you begin to dispense fuel using your Fuel Rewards<sup>®</sup> savings, you must dispense to the 20-gallon limit or you forfeit any remaining discount per gallons. If you have a Fuel Rewards<sup>®</sup> savings balance greater than the current per-gallon price of fuel, the price will roll down to the maximum capability of the fuel equipment, and your remaining Fuel Rewards<sup>®</sup> savings balance will be saved for a future fuel purchase. For purchases of $75 or more, please go inside to pay. Unbranded diesel and alternative fuels may not be eligible. The Fuel Rewards<sup>®</sup> program is owned and operated by Excentus Corporation. Offers may be modified or discontinued at any time without notice. Not valid where prohibited by law. Please see <a href="https://www.fuelrewards.com">www.fuelrewards.com</a> for complete Fuel Rewards<sup>®</sup> program details and Terms and Conditions. Other restrictions may apply. All trademarks are property of their respective owners.',
  FINAL:
    'FordPass® App, compatible with select smartphone platforms, is available via a download. Message and data rates may apply.<br/><br/>© 2024 Ford Motor Company. FordPass is a trademark of Ford or its affiliates. All rights reserved.<br/><br/>Cards are issued by First National Bank of Omaha (FNBO®), pursuant to a license from Visa U.S.A., Inc. Visa and Visa Signature are registered trademarks of Visa International Service Association and used under license.',
};
