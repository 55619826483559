import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { isMobile } from '../../../services/mobile-bridge/mobile-bridge';
import ServiceHandler from '../../../services/service-handler';
import styles from './popup-icons.module.scss';
export var DIRECTION;
(function (DIRECTION) {
    DIRECTION["RIGHT"] = "right center";
    DIRECTION["TOP"] = "top center";
    DIRECTION["LEFT"] = "left center";
    DIRECTION["BOTTOM"] = "bottom center";
})(DIRECTION || (DIRECTION = {}));
function PopupIcon(_a) {
    var _b, _c;
    var _d = _a.desktopDirection, desktopDirection = _d === void 0 ? DIRECTION.TOP : _d, children = _a.children, mobileDirection = _a.mobileDirection, id = _a.id, popupImage = _a.popupImage, letter = _a.letter, className = _a.className, mobileWidth = _a.mobileWidth;
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var closePopup = function () { return setOpen(false); };
    var cfg = ServiceHandler.ConfigService;
    // Logic to deal with mobile positioning
    var mobileView = screen.width < mobileWidth || isMobile();
    var direction = mobileView ? mobileDirection : desktopDirection;
    var arrowLocation = (_b = document === null || document === void 0 ? void 0 : document.getElementsByClassName('popup-arrow')[0]) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().x;
    var iconXLocation = (_c = document === null || document === void 0 ? void 0 : document.getElementById(id)) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect().x;
    var translateX = arrowLocation - iconXLocation;
    useEffect(function () {
        var firstTag = document.activeElement;
        firstTag.blur();
    }, [open]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Popup, { trigger: letter ? (React.createElement("sup", { className: "".concat(styles.supLetter, " ").concat(className) }, letter)) : (React.createElement("img", { id: id, "data-testid": "popup-".concat(id), src: popupImage ? cfg.AEM_BASE_URL + popupImage : cfg.AEM_BASE_URL + "/content/dam/loyalty/".concat(cfg.BRAND_NAME, "/us/en-us/images/info.svg"), alt: 'popup icon', className: styles.icon, tabIndex: 0, onKeyDown: function (e) {
                    if (e.key === 'Enter')
                        setOpen(true);
                }, onMouseDown: function (e) {
                    e.preventDefault();
                } })), position: direction, open: open, onClose: closePopup, onOpen: function () { return setOpen(true); }, className: 'left-position', arrowStyle: mobileView && {
                transform: "translateX(".concat(translateX, "px)"),
            } },
            React.createElement("div", { id: 'popup-container', className: styles.container },
                React.createElement("div", { id: 'popup-content', className: styles.content }, children),
                React.createElement("span", { id: 'popup-close', className: styles.close, onClick: closePopup })))));
}
export default PopupIcon;
