import React from 'react';
import styles from './earn-points-section.module.scss';
import PopupIcon, { DIRECTION } from '../../../components/common/popup-icons/popup-icons';
import cx from '../../../utils/classnames';
import ServiceHandler from '../../../services/service-handler';
var icon = "".concat(ServiceHandler.ConfigService.AEM_BASE_URL, "/content/dam/loyalty/lincoln/us/en-us/images/icons-rewards.png");
export var Eyeball = function (_a) {
    var id = _a.id, copy = _a.copy;
    return (React.createElement(PopupIcon, { desktopDirection: DIRECTION.TOP, mobileDirection: DIRECTION.TOP, id: id },
        React.createElement("div", { dangerouslySetInnerHTML: { __html: copy } })));
};
export var EarnTile = function (_a) {
    var points = _a.points, copy = _a.copy, children = _a.children, className = _a.className;
    return (React.createElement("div", { className: styles.tile },
        React.createElement("div", { className: cx(styles.points, className) },
            React.createElement("div", { className: styles.earnButtonContainer },
                React.createElement("span", null, points),
                ServiceHandler.ConfigService.isLAR && React.createElement("img", { className: styles.earnIcon, src: icon, alt: 'icon' }))),
        React.createElement("div", { className: styles.copy },
            React.createElement("span", null, copy),
            children)));
};
