import React, { useContext, useEffect } from 'react';
import { useContent } from '../../hooks/use-content';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
// Load views for different brands
//////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////
import styles from './fpr-overview-view.module.scss'; // eslint-disable-line no-redeclare
//////////
import cx from '../../utils/classnames';
import common from '../../utils/common.module.scss';
import utils from '../../utils/utils.module.scss';
import ServiceHandler from '../../services/service-handler';
// Load views for different brands
//////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////
import { footies } from '../footnotes/footnotes-fpr'; // eslint-disable-line no-redeclare
//////////
import TiersTable from '../../components/sections/tiers-table/tiers-table';
import TiersVisaCards from '../../components/sections/tiers-visa-cards/tiers-visa-cards';
import { CtaButton, CtaLink, CtaWhiteButton } from '../../components/common/cta/cta';
import PopupIcon from '../../components/common/popup-icons/popup-icons';
import EarnPointsSection from './earn-section/earn-points-section';
import { PointsHeroContext } from '../../context/points-hero-context';
import { useLocation } from 'react-router-dom';
import { DangerousHtml } from '../../components/common/dangerous-html/dangerous-html';
import DisclaimerFooter, { renderFootnote } from '../../components/sections/disclaimer-footer/disclaimer-footer';
import { FdsChevron } from '../../components/common/chevron/fds-chevron';
import OverviewTierItem from './tier-item/overview-tier-item';
import EarnPointsSectionMobile from './earn-section/earn-points-section-mobile';
var OverviewView = function () {
    var cfg = ServiceHandler.ConfigService;
    var _a = useContent('v2/ps-overview')[0], content = _a.content, getValueByTitle = _a.getValueByTitle, getHtmlByTitle = _a.getHtmlByTitle;
    var isDesktop = useContext(PointsHeroContext)[0].isDesktop;
    var params = new URLSearchParams(useLocation().search);
    useEffect(function () {
        var _a;
        if (content && params.get('ref')) {
            (_a = document.getElementById(params.get('ref'))) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
        }
    }, [content]);
    var footnotesFPR = {
        a: footies.MODEM1,
        b: footies.ACTIVATE_ACCOUNT,
        c: footies.TandC,
        d: footies.OTHER_LIMITATIONS,
        e: footies.TIER_LIMITATIONS,
    };
    var footnotesLAR = {
        a: footies.MODEM,
        b: footies.TIER_LEVEL,
        c: footies.TandC,
        d: footies.TIER_LIMITATIONS,
        e: footies.CALM,
        f: footies.CAR_WASH,
    };
    var tiers = [
        {
            image: "".concat(ServiceHandler.ConfigService.AEM_BASE_URL, "/content/dam/loyalty/lincoln/us/en-us/images/premiere-vehicle.png"),
            title: 'PREMIERE',
            content: ['40,000 Points for vehicle purchase/lease', '1-year Calm membership'],
        },
        {
            image: "".concat(ServiceHandler.ConfigService.AEM_BASE_URL, "/content/dam/loyalty/lincoln/us/en-us/images/reserve-vehicle.png"),
            title: 'RESERVE',
            content: ['50,000 Points for vehicle purchase/lease', '1-year Calm membership'],
        },
        {
            image: "".concat(ServiceHandler.ConfigService.AEM_BASE_URL, "/content/dam/loyalty/lincoln/us/en-us/images/black-label-vehicle.png"),
            title: 'BLACK LABEL',
            content: [
                '70,000 Points for vehicle purchase/lease',
                '1-year Calm membership',
                'Early access to redeem rewards',
                'Exclusive rewards and experiences',
                "Complimentary Car Washes (Anytime Car Washes)",
                'Vehicle Detailing opportunities with Point Redemption',
                'Remote New Vehicle Delivery',
                'Avis® President’s Club membership',
            ],
        },
    ];
    return content ? (React.createElement("div", { className: styles.mainContainer },
        React.createElement("div", { id: 'Header', className: cx(styles.header), style: {
                backgroundImage: "url(".concat(isDesktop ? "".concat(cfg.AEM_BASE_URL).concat(getValueByTitle('hero-image')) : "".concat(cfg.AEM_BASE_URL).concat(getValueByTitle('hero-image-mobile')), ")"),
            } },
            React.createElement("div", { className: cx(styles.headerContent) },
                React.createElement("div", { className: cx(common.h2, styles.headerMainText) }, getValueByTitle('hero-title')),
                React.createElement("div", { className: styles.subheaderContent }, ServiceHandler.AuthenticationService.getIsAuthenticated() ? getValueByTitle('subheader-title-auth') : getValueByTitle('subheader-title')),
                React.createElement("div", { className: cx(common.mT32, styles.flexRow) },
                    React.createElement(CtaWhiteButton, { link: '/dashboard', label: ServiceHandler.AuthenticationService.getIsAuthenticated() ? getValueByTitle('hero-cta-auth') : getValueByTitle('hero-cta-unauth') })),
                cfg.isLAR && React.createElement("div", null,
                    React.createElement("a", { href: "".concat(cfg.AEM_BASE_URL, "/content/dam/loyalty/common/pdf/LAR-lookbook.pdf"), className: styles.overviewHeaderLookbook },
                        getValueByTitle('overviewHeaderLookbook'),
                        " ",
                        React.createElement(FdsChevron, { direction: 'right' }))))),
        cfg.isFPR && React.createElement("div", { id: 'redeem' },
            React.createElement("div", { className: cx(utils.centerTop, styles.redeemSection) },
                React.createElement("div", { className: utils.twoColumn },
                    getValueByTitle('redeem-your-points-image') && (React.createElement("div", { className: cx(utils.column, utils.leftColumn, styles.image) },
                        React.createElement("img", { title: 'redeem-your-points-image', src: cfg.AEM_BASE_URL + getValueByTitle('redeem-your-points-image') }))),
                    React.createElement("div", { className: cx(utils.column, styles.content) },
                        React.createElement("h2", { className: cx(styles.title, common.h3) }, getValueByTitle('redeem-your-points-title')),
                        React.createElement(DangerousHtml, { html: getHtmlByTitle('redeem-your-points-subtext'), className: cx(styles.subtext, common.subtext) }),
                        React.createElement("div", { className: cx(common.mT30, styles.mL20) },
                            React.createElement(CtaButton, { label: getValueByTitle('view-all-rewards-button'), link: '/rewards' })))))),
        isDesktop ? React.createElement(EarnPointsSection, null) : React.createElement(EarnPointsSectionMobile, null),
        cfg.isFPR && React.createElement("div", null,
            React.createElement("div", { id: 'benefits', className: styles.tierBenefits },
                React.createElement("div", { className: common.h3 }, getValueByTitle('tier-benefits-title')),
                React.createElement("div", { className: styles.subheading }, getValueByTitle('tier-benefits-subtitle')),
                React.createElement(TiersTable, null)),
            React.createElement("div", { id: 'tier-status', className: cx(styles.activities) },
                React.createElement("div", null,
                    React.createElement("img", { src: cfg.AEM_BASE_URL + getValueByTitle('tiers-activities-image'), alt: 'image of women laughing and socializing outside restaurant' })),
                React.createElement("div", { className: styles.activitiesText },
                    React.createElement("div", { className: styles.activitiesTitle }, getValueByTitle('tiers-activities-title')),
                    React.createElement("div", { className: styles.activitiesSub },
                        React.createElement(React.Fragment, null,
                            'As you complete these Tier Activities',
                            React.createElement(PopupIcon, { letter: 'e', id: 'popup-e' },
                                React.createElement(DangerousHtml, { html: renderFootnote('e', footnotesFPR) })),
                            getValueByTitle('tiers-activities-details'))),
                    React.createElement(DangerousHtml, { className: styles.activitiesList, html: getHtmlByTitle('tiers-activities-bulletpoints') })))),
        cfg.isLAR && React.createElement("div", { id: 'tier-status' },
            React.createElement("hr", { className: styles.hr }),
            React.createElement("div", { className: styles.tierContainer },
                React.createElement("div", { className: styles.tierTopBorder }),
                React.createElement("div", { className: styles.tierTitle }, getValueByTitle('tiers-activities-title')),
                React.createElement("div", { className: styles.tierSubText }, getValueByTitle('tiers-activities-details')),
                React.createElement("div", { style: isDesktop ? { display: 'flex', flexDirection: 'row', marginTop: '16px', textAlign: 'center' } : { display: 'flex', flexDirection: 'column', marginTop: '16px', textAlign: 'center' } }, tiers.map(function (item, index) {
                    return React.createElement(OverviewTierItem, { key: index, image: item === null || item === void 0 ? void 0 : item.image, content: item === null || item === void 0 ? void 0 : item.content, title: item === null || item === void 0 ? void 0 : item.title });
                })))),
        cfg.isLAR &&
            React.createElement("div", { id: 'redeem' },
                React.createElement("div", { className: cx(styles.redeemLAR), style: isDesktop ? { backgroundImage: "url(".concat(ServiceHandler.ConfigService.AEM_BASE_URL, "/content/dam/loyalty/lincoln/us/en-us/images/redeem-desktop.png)") } : { backgroundImage: "url(".concat(ServiceHandler.ConfigService.AEM_BASE_URL, "/content/dam/loyalty/lincoln/us/en-us/images/media.png)") } },
                    React.createElement("div", { className: cx(styles.redeemLARContent) },
                        React.createElement("div", null,
                            React.createElement("h2", { className: cx(styles.larTitle) }, getValueByTitle('redeem-your-points-title')),
                            React.createElement(DangerousHtml, { html: getHtmlByTitle('redeem-your-points-subtext'), className: cx(styles.larSubtext) })),
                        React.createElement("div", { className: cx(common.mT32) },
                            React.createElement(CtaWhiteButton, { label: getValueByTitle('view-all-rewards-button'), link: '/rewards' }))))),
        cfg.isFPR && React.createElement("div", { id: 'Visa', className: styles.visa },
            React.createElement("div", null,
                React.createElement("div", { className: cx(common.h3, styles.visaTitle) },
                    getValueByTitle('advance-your-tier-title'),
                    " "),
                React.createElement("div", { className: styles.visaSub }, getValueByTitle('advance-your-tier-subtitle')),
                React.createElement(TiersVisaCards, null),
                React.createElement(CtaLink, { label: 'Get Details', link: '/credit-card' }))),
        React.createElement("div", { id: 'faqs', className: cx(styles.overviewSection, styles.faqs) },
            React.createElement("div", { className: cx(common.h3, styles.overviewSectionTitle) }, getValueByTitle('faq-title')),
            React.createElement("div", { className: styles.overviewSectionSubTitle }, getValueByTitle('faq-sub')),
            React.createElement("div", { className: common.mT30 },
                ServiceHandler.ConfigService.isFPR && React.createElement(CtaButton, { label: 'View FAQs', link: '/faqs' }),
                ServiceHandler.ConfigService.isLAR && React.createElement(CtaLink, { label: 'View FAQS', link: '/faqs' }))),
        React.createElement("div", { id: 'dashboard', className: cx(styles.overviewSection), style: { backgroundColor: 'white' } },
            React.createElement("div", { className: cx(common.h3, styles.overviewSectionTitle) }, getValueByTitle('start-getting-rewarded-title')),
            React.createElement("div", { className: common.mT30 },
                React.createElement(CtaButton, { link: '/dashboard', label: ServiceHandler.AuthenticationService.getIsAuthenticated()
                        ? getValueByTitle('dashboard-auth-cta')
                        : getValueByTitle('dashboard-unauth-cta') }))),
        React.createElement("div", { className: styles.backgroundDisclaimer }, cfg.isFPR ? React.createElement(DisclaimerFooter, { ids: ['a', 'b', 'c', 'd', 'e'], footnotes: footnotesFPR }) : React.createElement(DisclaimerFooter, { ids: ['a', 'b', 'c', 'd', 'e', 'f'], footnotes: footnotesLAR })))) : (React.createElement(ActivityIndicator, null));
};
export default OverviewView;
