export const footies = {
  OFFERS_VARY:
    'Offers may vary depending on where you apply, for example online or in person. To take advantage of this offer, apply now directly through this advertisement. Review offer details before you apply.',
  OFFERS_VARY_SUB:
    'Please see the {MAGIC_SOCT_URL}. for important information on rates, fees, costs, conditions, and limitations. Minimum monthly payments required.<br/><br><B>Please note you must pay your entire statement balance (with the exception of purchases made with this promotional rate during its promotional period) in full by the payment due date each month to avoid being charged interest on new non-promotional purchases from the date those purchases are made.</b><br/><br/>Special Financing Offer Details: Purchases in which single or multiple items are purchased in the same transaction totaling at least $499 at Lincoln Retailers will be referred to as “Eligible Purchases” in your {MAGIC_SOCT_URL}. All transactions are subject to approval. Accounts are eligible only if they are open, in good standing and not already subject to special terms. All other terms of your account remain in effect. Minimum Monthly Payments required.',
  CARDHOLDER_DISCLAIMERS:
    'Important Information<br/><br/>Please see your Rates and Terms Schedule for important information on rates, fees, costs, conditions, and limitations.<br/><br/>We reserve the right to change your account terms for reasons described in your Cardmember Agreement.<br/><br/><b>Please note you must pay your entire statement balance (with the exception of purchases made with this promotional rate during its promotional period) in full by the payment due date each month to avoid being charged interest on new non-promotional purchases from the date those purchases are made.</b>',
  TandC:
    "See the Lincoln Access Rewards™ Visa® terms and conditions in the {MAGIC_SOCT_URL}. Points are not redeemable for cash or check, and have no monetary value. Please see the Lincoln Access Rewards™ Program <a href='https://www.lincoln.com/lincolnway/en_us/termsprivacy/'>terms and conditions</a> for information regarding expiration, redemption, forfeiture and other limitations on Lincoln Access Rewards Points. Must have an activated Lincoln Access Rewards account to receive Lincoln Access Rewards Points.",
  APR: 'For additional information about Annual Percentage Rates (APRs), fees, and other costs, see the {MAGIC_SOCT_URL}.',
  FINAL:
    'The Lincoln Way® App, compatible with select smartphone platforms, is available via a download. Message and data rates may apply.<br/><br/>© 2024 Ford Motor Company. Lincoln, Lincoln Way, and Lincoln Access Rewards are trademarks of Ford or its affiliates. All rights reserved.<br/><br/>Cards are issued by First National Bank of Omaha (FNBO®), pursuant to a license from Visa U.S.A Inc. Visa and Visa Signature are registered trademarks of Visa International Service Association and used under license.',
  MODEM:
    'Within 60 days of vehicle sale, must activate your modem through the Lincoln Way® App and activate your Lincoln Access Rewards™ account to receive Points. Visit a participating retailer for details. Points are not redeemable for cash and have no monetary value. Point earning and redemption values are approximate and vary by products and services redeemed. See the Lincoln Access Rewards Program <a href="https://www.lincoln.com/lincolnway/en_us/termsprivacy/">terms and conditions</a> information regarding expiration, redemption, forfeiture, and other limitations on Lincoln Access Rewards Points.',
  CAR_WASH:
    'During life of ownership for first owner at any Lincoln Black Label Retailer. Availability depends on retailer participation. See retailer for details.',
  CALM: 'Lincoln will offer a complimentary 12-month subscription to Calm through the Lincoln Way® App or Lincoln.com for current owners of a 2023, 2024, or 2025 model year vehicle with a registered vehicle identification number (VIN) in a Lincoln Way account. Subscription period begins from the date of activation. Promotion ends 12/31/25. Don’t drive while distracted or while using handheld devices. Use voice-operated systems when possible. Program may be modified, suspended, or terminated at any time by Lincoln.',
  TIER_LEVEL:
    'Your initial tier level will match the trim level of the Lincoln Certified Pre-Owned vehicle purchased. Modem activation not required.',
  ENROLLED:
    'Must be enrolled in Lincoln Access Rewards<sup>™</sup> to receive Lincoln Access Points. Modem must be activated within 60 days of purchase on Lincoln vehicles through the Lincoln Way<sup>®</sup> App on a smartphone and remain active for at least 180 days after activation. Buyers of non-modem equipped vehicles will also receive Points after enrollment. Visit a participating retailer today for details. Points are not redeemable for cash and have no monetary value. Points earning and redemption values are approximate and vary by products and services redeemed. See the Lincoln Access Rewards Program <a href="https://www.lincoln.com/lincolnway/en_us/termsprivacy/"><u>terms and conditions</u></a> information regarding expiration, redemption, forfeiture, and other limitations on Lincoln Access Rewards Points',
  TIER_LIMITATIONS:
    'Limitations apply. Earn Points for the purchase of Ford, Motorcraft®, or Omnicraft® parts and associated labor at a participating Lincoln retailer. See Lincoln Access Rewards™ Terms and FAQs. Must have an activated Lincoln Access Rewards account within 60 days of service invoice date to receive Lincoln Access Rewards Points.',
  SHELL_DISCLAIMER:
    'Link your Lincoln Access Rewards™ and your Fuel Rewards® accounts to save 5¢/gal* on your fill-ups at participating Shell stations.',
  SHELL_DISCLAIMER_GOLDSTAR:
    '*Restrictions apply. Offer valid from January 13, 2025 through January 12, 2026 (the "Offer Period"). Receive ongoing Fuel Rewards® Complimentary Gold Status (the “Gold Status Reward”) when you successfully link your Fuel Rewards® account to your Lincoln Access Rewards account at <a href="https://join.fuelrewards.com/frconnect/registration.html?partSrcId=lincolnaccess">https://join.fuelrewards.com/frconnect/registration.html?partSrcId=lincolnaccess</a>. To qualify for this offer, you must (i) register either as a new Fuel Rewards® member or log in with your existing Fuel Rewards® member information and (ii) successfully link your Lincoln Access Rewards account to your Fuel Rewards® account. Upon successful completion of the above, you will receive 5¢/gal in Fuel Rewards® savings on up to 20 gallons on your fill-ups at participating Shell stations during the Offer Period. To redeem the Gold Status Reward, swipe your Fuel Rewards® card, a linked payment card, or enter your Alt ID at a participating Shell station and complete your fill-up. Gold Status Rewards offered to Lincoln Access Rewards members cannot be combined with tier status rewards from any other Fuel Rewards® program offer but can be combined with other rewards in your Fuel Rewards® account. It may not be possible to combine multiple discounts and/or rewards in a single transaction. Visit <a href="https://www.fuelrewards.com/status">fuelrewards.com/status</a> for full tier status terms.',
  SHELL_DISCLAIMER_GOLD:
    'Restrictions apply. Offer valid from January 13, 2025 through January 12, 2026 (the "Offer Period"). Receive ongoing Fuel Rewards® Complimentary Gold Status (the “Gold Status Reward”) when you successfully link your Fuel Rewards® account to your Lincoln Access Rewards account at <a href="https://join.fuelrewards.com/frconnect/registration.html?partSrcId=lincolnaccess">https://join.fuelrewards.com/frconnect/registration.html?partSrcId=lincolnaccess</a>. To qualify for this offer, you must (i) register either as a new Fuel Rewards® member or log in with your existing Fuel Rewards® member information and (ii) successfully link your Lincoln Access Rewards account to your Fuel Rewards® account. Upon successful completion of the above, you will receive 5¢/gal in Fuel Rewards® savings on up to 20 gallons on your fill-ups at participating Shell stations during the Offer Period. To redeem the Gold Status Reward, swipe your Fuel Rewards® card, a linked payment card, or enter your Alt ID at a participating Shell station and complete your fill-up. Gold Status Rewards offered to Lincoln Access Rewards members cannot be combined with tier status rewards from any other Fuel Rewards® program offer but can be combined with other rewards in your Fuel Rewards® account. It may not be possible to combine multiple discounts and/or rewards in a single transaction. Visit <a href="https://www.fuelrewards.com/status">fuelrewards.com/status</a> for full tier status terms.',
  SHELL_DISCLAIMER_GOLD_2:
    'Void where prohibited, only available in US. Fuel Rewards<sup>®</sup> savings are limited to 20 gallons of fuel per purchase per vehicle or fraud limits placed by Shell and/or limits placed on your payment card by your financial institution, each of which may be lower. Dispenser may require a price of up to $0.109/gallon. Once you begin to dispense fuel using your Fuel Rewards<sup>®</sup> savings, you must dispense to the 20-gallon limit or you forfeit any remaining discount per gallons. If you have a Fuel Rewards<sup>®</sup> savings balance greater than the current per-gallon price of fuel, the price will roll down to the maximum capability of the fuel equipment, and your remaining Fuel Rewards<sup>®</sup> savings balance will be saved for a future fuel purchase. For purchases of $75 or more, please go inside to pay. Unbranded diesel and alternative fuels may not be eligible. The Fuel Rewards<sup>®</sup> program is owned and operated by Excentus Corporation. Offers may be modified or discontinued at any time without notice. Not valid where prohibited by law. Please see <a href="https://www.fuelrewards.com">www.fuelrewards.com</a> for complete Fuel Rewards<sup>®</sup> program details and Terms and Conditions. Other restrictions may apply. All trademarks are property of their respective owners.',
};
