import React from 'react';
import styles from './tiers-table.module.scss';
import { useContent } from '../../../hooks/use-content';
import ServiceHandler from '../../../services/service-handler';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import InfoPaginationCarousel from '../info-pagination-carousel/info-pagination-carousel';
import cx from '../../../utils/classnames';
var TiersTable = function () {
    var _a = useContent('v2/tiers-table')[0], content = _a.content, getValueByTitle = _a.getValueByTitle;
    var cfg = ServiceHandler.ConfigService;
    function MobileTable(_a) {
        var tier = _a.tier, title = _a.title;
        return (React.createElement("div", { className: cx(styles.tiersTable) },
            React.createElement("table", { className: cx(styles.ShowMobile) },
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: cx(styles.height, tier === 'bronze' ? styles.bronze : tier === 'silver' ? styles.silver : tier === 'blue' ? styles.blue : '') },
                            React.createElement("div", { key: title },
                                React.createElement("div", { className: styles.title }, getValueByTitle("".concat(tier, "-title"))),
                                React.createElement("div", { className: styles.subheader }, getValueByTitle("".concat(tier, "-activities")))))),
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            React.createElement("div", { className: styles.font }, getValueByTitle('ford-service')),
                            React.createElement("div", { className: styles.lineTwo }, getValueByTitle("".concat(tier, "-offers"))))),
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            React.createElement("div", { className: styles.font }, getValueByTitle('partner-offers')),
                            React.createElement("div", null, tier === 'silver' || tier === 'blue' ? (React.createElement("img", { src: cfg.AEM_BASE_URL + getValueByTitle('checkmark-icon'), className: styles.image })) : ('')))),
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            React.createElement("div", { className: styles.font }, getValueByTitle('even-more-coming-in')),
                            React.createElement("div", null, tier === 'bronze' || tier === 'silver' || tier === 'blue' ? (React.createElement("img", { src: cfg.AEM_BASE_URL + getValueByTitle('checkmark-icon'), className: styles.image })) : (''))))))));
    }
    return content ? (React.createElement("div", { className: styles.tiersTable },
        React.createElement("table", { className: styles.ShowDesktop },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("th", null),
                    React.createElement("th", null,
                        React.createElement("div", { className: styles.title }, getValueByTitle('bronze-title')),
                        React.createElement("div", { className: styles.subheader }, getValueByTitle('bronze-activities'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: styles.title }, getValueByTitle('silver-title')),
                        React.createElement("div", { className: styles.subheader }, getValueByTitle('silver-activities'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: styles.title }, getValueByTitle('blue-title')),
                        React.createElement("div", { className: styles.subheader }, getValueByTitle('blue-activities')))),
                React.createElement("tr", null,
                    React.createElement("td", null, getValueByTitle('ford-service')),
                    React.createElement("td", null, getValueByTitle('bronze-offers')),
                    React.createElement("td", null, getValueByTitle('silver-offers')),
                    React.createElement("td", null, getValueByTitle('blue-offers'))),
                React.createElement("tr", null,
                    React.createElement("td", null, getValueByTitle('even-more-coming-in')),
                    React.createElement("td", null,
                        React.createElement("img", { src: cfg.AEM_BASE_URL + getValueByTitle('checkmark-icon'), className: styles.image })),
                    React.createElement("td", null,
                        React.createElement("img", { src: cfg.AEM_BASE_URL + getValueByTitle('checkmark-icon'), className: styles.image })),
                    React.createElement("td", null,
                        React.createElement("img", { src: cfg.AEM_BASE_URL + getValueByTitle('checkmark-icon'), className: styles.image }))),
                React.createElement("tr", null,
                    React.createElement("td", null, getValueByTitle('partner-offers')),
                    React.createElement("td", null),
                    React.createElement("td", null,
                        React.createElement("img", { src: cfg.AEM_BASE_URL + getValueByTitle('checkmark-icon'), className: styles.image })),
                    React.createElement("td", null,
                        React.createElement("img", { src: cfg.AEM_BASE_URL + getValueByTitle('checkmark-icon'), className: styles.image }))))),
        React.createElement("div", { className: cx(styles.ShowMobile, styles.bottom) },
            React.createElement(InfoPaginationCarousel, null,
                React.createElement(MobileTable, { tier: 'bronze', title: 'bronze' }),
                React.createElement(MobileTable, { tier: 'silver', title: 'silver' }),
                React.createElement(MobileTable, { tier: 'blue', title: 'blue' }))))) : (React.createElement(ActivityIndicator, null));
};
export default TiersTable;
