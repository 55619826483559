import React from 'react';
import ServiceHandler from '../../../services/service-handler';
import PopupIcon, { DIRECTION } from '../../../components/common/popup-icons/popup-icons';
import styles from '../lar-overview-view.module.scss';
import { DangerousHtml } from '../../../components/common/dangerous-html/dangerous-html';
import { footies } from '../../footnotes/footnotes-lar';
import { renderFootnote } from '../../../components/sections/disclaimer-footer/disclaimer-footer';
var footnotes = {
    e: footies.CALM,
    f: footies.CAR_WASH,
};
var OverviewTierItem = function (_a) {
    var image = _a.image, title = _a.title, content = _a.content;
    var cfg = ServiceHandler.ConfigService;
    var showB = 1;
    var showE1 = 3;
    var showE2 = 4;
    return (React.createElement("div", { style: { margin: '3%', display: 'flex', flexDirection: 'column', alignItems: 'center' } },
        React.createElement("img", { className: styles.overViewImage, src: image }),
        React.createElement("div", { className: styles.overviewItemTitle }, title),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column', alignItems: 'center' } }, content === null || content === void 0 ? void 0 : content.map(function (item, index) {
            return (React.createElement("div", { key: index, style: { marginBottom: '20px' } },
                React.createElement("div", { style: { width: "".concat(showE1 === index || showE2 === index ? '280px' : '330px') }, className: "".concat(styles.overViewItemRow, " ").concat(showE1 === index ? styles.showE1 : showE2 === index ? styles.showE2 : ''), key: index },
                    item,
                    (index === showB || index === showE2) && (React.createElement(PopupIcon, { key: index, letter: "".concat(index === showB ? 'e' : 'f'), desktopDirection: DIRECTION.RIGHT, mobileDirection: DIRECTION.LEFT, mobileWidth: 700, id: '', className: "".concat(styles.supLetter, " ").concat(cfg.isLAR && styles.supLetterLar) },
                        React.createElement(DangerousHtml, { html: renderFootnote(index === showB ? 'e' : 'f', footnotes) }))))));
        }))));
};
export default OverviewTierItem;
