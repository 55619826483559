import React, { useState, useEffect, useRef } from 'react';
import cx from '../../../utils/classnames';
import { FdsChevron } from '../../common/chevron/fds-chevron';
import utils from '../../../utils/utils.module.scss';
import styles from './info-pagination-carousel.module.scss';
import ServiceHandler from '../../../services/service-handler';
var InfoPaginationCarousel = function (_a) {
    var _b, _c, _d, _e;
    var children = _a.children;
    var _f = useState(0), currentPage = _f[0], setCurrentPage = _f[1];
    var containerRef = useRef(null);
    var cfg = ServiceHandler.ConfigService;
    useEffect(function () {
        var handleScroll = function () {
            if (containerRef.current) {
                var scrollLeft = containerRef.current.scrollLeft;
                var childWidth = containerRef.current.clientWidth * 0.78;
                var newPage = Math.round(scrollLeft / (childWidth + 20));
                setCurrentPage(newPage);
            }
        };
        var container = containerRef.current;
        container === null || container === void 0 ? void 0 : container.addEventListener('scroll', handleScroll);
        return function () { return container === null || container === void 0 ? void 0 : container.removeEventListener('scroll', handleScroll); };
    }, []);
    var scrollToPage = function (page) {
        if (containerRef.current) {
            var childWidth = containerRef.current.clientWidth * 0.78;
            containerRef.current.scrollTo({
                left: page * (childWidth + 20),
                behavior: 'smooth',
            });
            setCurrentPage(page);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("ul", { className: cx(styles.container), ref: containerRef }, children.map(function (child, index) { return (React.createElement("li", { key: index }, child)); })),
        children.length > 0 && (React.createElement("div", { className: utils.notDesktop },
            React.createElement("div", { className: cx(styles.carouselIndicator) },
                React.createElement("button", { className: cx(styles.directionalButton, (_b = {}, _b[styles.fpr] = cfg.isFPR, _b), (_c = {}, _c[styles.lar] = cfg.isLAR, _c), styles.iconLeft, currentPage === 0 ? styles.disable : ''), "aria-label": 'previous button', onClick: function () { return scrollToPage(currentPage - 1); }, disabled: currentPage === 0 },
                    React.createElement(FdsChevron, { className: cx(styles.chevronSize, styles.chevronLeft, utils.fprOnly), direction: 'left' })),
                React.createElement("div", { className: cx(styles.text, utils.fprOnly) }, "".concat(currentPage + 1, "/").concat(children.length)),
                React.createElement("div", { className: cx(utils.larOnly) },
                    React.createElement("ul", { className: cx(styles.pancakeButtons) }, children.map(function (child, i) {
                        var _a;
                        return (React.createElement("li", { key: 'slide' + i },
                            React.createElement("button", { onClick: function () { return scrollToPage(i); }, className: styles.pancakeButton, "aria-label": "Click or press enter to view ".concat(i, " of 3") },
                                React.createElement("span", { className: cx(styles.indicator, (_a = {}, _a[styles.indicatorCurrent] = currentPage == i, _a)) }))));
                    }))),
                React.createElement("button", { className: cx(styles.directionalButton, (_d = {}, _d[styles.fpr] = cfg.isFPR, _d), (_e = {}, _e[styles.lar] = cfg.isLAR, _e), styles.iconRight, currentPage === children.length - 1 ? styles.disable : ''), "aria-label": 'next button', onClick: function () { return scrollToPage(currentPage + 1); }, disabled: currentPage === children.length - 1 },
                    React.createElement(FdsChevron, { className: cx(styles.chevronSize, styles.chevronRight, utils.fprOnly), direction: 'right' })))))));
};
export default InfoPaginationCarousel;
