import React, { useState } from 'react';
import { useContent } from '../../../hooks/use-content';
import styles from './earn-points-section.module.scss';
import cx from '../../../utils/classnames';
//////////////////////
////////////////////////////////////////////////////////
/////////
import { footies } from '../../footnotes/footnotes-fpr';
//////////
import PopupIcon, { DIRECTION } from '../../../components/common/popup-icons/popup-icons';
import { EarnSection } from '../earn-section/earn-section';
import ServiceHandler from '../../../services/service-handler';
import common from '../../../utils/common.module.scss';
import { FdsChevron } from '../../../components/common/chevron/fds-chevron';
import { DangerousHtml } from '../../../components/common/dangerous-html/dangerous-html';
import { renderFootnote } from '../../../components/sections/disclaimer-footer/disclaimer-footer';
import { EarnTile } from './earn-tile';
//////////////////////
///////////////////
//////////////////////
////////////////////////
///////////////////
//////////////////////////////
//////////////////
//
/////////
var footnotes = {
    a: footies.TIER_LIMITATIONS,
    b: footies.MODEM1,
    c: footies.MODEM2,
    d: footies.BLUE_ADVANTAGE,
    e: footies.FORDPASS_VISA,
};
//////////
function EarnPointsSectioMobile() {
    var cfg = ServiceHandler.ConfigService;
    var isLAR = cfg === null || cfg === void 0 ? void 0 : cfg.isLAR;
    var _a = useState(false), newTab = _a[0], setNewTab = _a[1];
    var _b = useState(false), visaTab = _b[0], setVisaTab = _b[1];
    var _c = useState(false), otherTab = _c[0], setOtherTab = _c[1];
    var getValueByTitle = useContent('v2/overview-tabbar')[0].getValueByTitle;
    var plus = "".concat(ServiceHandler.ConfigService.AEM_BASE_URL, "/content/dam/loyalty/lincoln/us/en-us/images/icons-add.png");
    var minus = "".concat(ServiceHandler.ConfigService.AEM_BASE_URL, "/content/dam/loyalty/lincoln/us/en-us/images/icons-minimize.png");
    return (React.createElement("div", { id: 'earn', className: styles.earn },
        React.createElement("div", null,
            React.createElement("div", { className: cx(common.h3, styles.earnTitle) }, getValueByTitle('title')),
            React.createElement("div", { className: styles.tabbar },
                React.createElement("div", { className: "".concat(isLAR && styles.tabbarButtonContainer, " ").concat(isLAR && styles.tabbarButtonContainerBorder) },
                    React.createElement("button", { className: "".concat(styles.tabbarButton, " ").concat(newTab && styles.tabbarButtonActive), onClick: function () { return setNewTab(!newTab); } },
                        getValueByTitle('tab1-title'),
                        isLAR ? React.createElement("img", { src: newTab ? minus : plus, alt: 'openIcon' }) : React.createElement(FdsChevron, { direction: newTab ? 'up' : 'down' })),
                    isLAR && newTab && (React.createElement("div", { className: styles.tabbarDetails },
                        React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('points1-value'), copy: getValueByTitle('points1-copy') },
                            React.createElement(PopupIcon, { letter: 'a', desktopDirection: DIRECTION.RIGHT, mobileDirection: DIRECTION.LEFT, mobileWidth: 450, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: renderFootnote('a', footnotes) }))),
                        React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('points2-value'), copy: getValueByTitle('points2-copy') },
                            React.createElement(PopupIcon, { letter: 'a', desktopDirection: DIRECTION.RIGHT, mobileDirection: DIRECTION.LEFT, mobileWidth: 450, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: renderFootnote('a', footnotes) }))),
                        React.createElement(EarnSection, { isLar: isLAR, className: styles.earnSectionRight, points: getValueByTitle('points3-value'), copy: getValueByTitle('points3-copy') },
                            React.createElement(PopupIcon, { letter: 'a', desktopDirection: DIRECTION.RIGHT, mobileDirection: DIRECTION.LEFT, mobileWidth: 450, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: renderFootnote('a', footnotes) }))),
                        React.createElement(EarnSection, { isLar: isLAR, className: styles.upTo22000, points: getValueByTitle('points4-value'), copy: getValueByTitle('points4-copy') },
                            React.createElement(PopupIcon, { letter: 'b', desktopDirection: DIRECTION.RIGHT, mobileDirection: DIRECTION.TOP, mobileWidth: 450, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: renderFootnote('b', footnotes) }))))),
                    !isLAR && newTab && (React.createElement("div", { className: styles.tabbarDetails },
                        React.createElement(EarnTile, { points: getValueByTitle('points1-value'), copy: getValueByTitle('points1-copy') },
                            React.createElement(PopupIcon, { letter: 'b', desktopDirection: DIRECTION.RIGHT, id: '', className: styles.supLetter },
                                React.createElement(DangerousHtml, { html: renderFootnote('b', footnotes) }))),
                        React.createElement(EarnTile, { points: getValueByTitle('points2-value'), copy: getValueByTitle('points2-copy') },
                            React.createElement(PopupIcon, { letter: 'b', desktopDirection: DIRECTION.RIGHT, id: '', className: styles.supLetter },
                                React.createElement(DangerousHtml, { html: renderFootnote('b', footnotes) }))),
                        React.createElement(EarnTile, { className: styles.earnSectionRight, points: getValueByTitle('points3-value'), copy: getValueByTitle('points3-copy') },
                            React.createElement(PopupIcon, { letter: 'c', desktopDirection: DIRECTION.RIGHT, id: '', className: styles.supLetter },
                                React.createElement(DangerousHtml, { html: renderFootnote('c', footnotes) }))),
                        React.createElement(EarnTile, { className: styles.upTo22000, points: getValueByTitle('points4-value'), copy: getValueByTitle('points4-copy') },
                            React.createElement(PopupIcon, { letter: 'd', desktopDirection: DIRECTION.RIGHT, id: '', className: styles.supLetter },
                                React.createElement(DangerousHtml, { html: renderFootnote('d', footnotes) })))))),
                React.createElement("div", null,
                    React.createElement("button", { className: "".concat(styles.tabbarButton, " ").concat(visaTab && styles.tabbarButtonActive), onClick: function () { return setVisaTab(!visaTab); } },
                        getValueByTitle('tab2-title'),
                        isLAR ? React.createElement("img", { src: visaTab ? minus : plus, alt: 'openIcon' }) : React.createElement(FdsChevron, { direction: visaTab ? 'up' : 'down' })),
                    isLAR && visaTab && (React.createElement("div", { className: styles.tabbarDetails },
                        React.createElement("div", null,
                            React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('visa1-value'), copy: getValueByTitle('visa1-copy') },
                                React.createElement(PopupIcon, { letter: 'c', desktopDirection: DIRECTION.RIGHT, mobileDirection: DIRECTION.LEFT, mobileWidth: 450, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                    React.createElement(DangerousHtml, { html: renderFootnote('c', footnotes) }))),
                            React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('visa2-value'), copy: getValueByTitle('visa2-copy') },
                                React.createElement(PopupIcon, { letter: 'c', desktopDirection: DIRECTION.RIGHT, mobileDirection: DIRECTION.TOP, mobileWidth: 450, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                    React.createElement(DangerousHtml, { html: renderFootnote('c', footnotes) })))),
                        React.createElement("div", { className: styles.earnSectionRight },
                            React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('visa3-value'), copy: getValueByTitle('visa3-copy') },
                                React.createElement(PopupIcon, { letter: 'c', desktopDirection: DIRECTION.RIGHT, mobileDirection: DIRECTION.LEFT, mobileWidth: 450, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                    React.createElement(DangerousHtml, { html: renderFootnote('c', footnotes) }))),
                            React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('visa4-value'), copy: getValueByTitle('visa4-copy') },
                                React.createElement(PopupIcon, { letter: 'c', desktopDirection: DIRECTION.RIGHT, mobileDirection: DIRECTION.TOP, mobileWidth: 450, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                    React.createElement(DangerousHtml, { html: renderFootnote('c', footnotes) })))))),
                    !isLAR && visaTab && (React.createElement("div", { className: styles.tabbarDetails },
                        React.createElement("div", null,
                            React.createElement(EarnTile, { points: getValueByTitle('visa1-value'), copy: getValueByTitle('visa1-copy') },
                                React.createElement(PopupIcon, { letter: 'e', desktopDirection: DIRECTION.RIGHT, id: '', className: styles.supLetter },
                                    React.createElement(DangerousHtml, { html: renderFootnote('e', footnotes) }))),
                            React.createElement(EarnTile, { points: getValueByTitle('visa2-value'), copy: getValueByTitle('visa2-copy') },
                                React.createElement(PopupIcon, { letter: 'e', desktopDirection: DIRECTION.RIGHT, id: '', className: styles.supLetter },
                                    React.createElement(DangerousHtml, { html: renderFootnote('e', footnotes) })))),
                        React.createElement("div", { className: styles.earnSectionRight },
                            React.createElement(EarnTile, { points: getValueByTitle('visa3-value'), copy: getValueByTitle('visa3-copy') },
                                React.createElement(PopupIcon, { letter: 'e', desktopDirection: DIRECTION.RIGHT, id: '', className: styles.supLetter },
                                    React.createElement(DangerousHtml, { html: renderFootnote('e', footnotes) }))),
                            React.createElement(EarnTile, { points: getValueByTitle('visa4-value'), copy: getValueByTitle('visa4-copy') },
                                React.createElement(PopupIcon, { letter: 'e', desktopDirection: DIRECTION.RIGHT, id: '', className: styles.supLetter },
                                    React.createElement(DangerousHtml, { html: renderFootnote('e', footnotes) }))))))),
                React.createElement("div", { className: "".concat(isLAR && styles.tabbarButtonContainer, " ").concat(isLAR && styles.tabbarButtonContainerBorder) },
                    React.createElement("button", { className: "".concat(styles.tabbarButton, " ").concat(otherTab && styles.tabbarButtonActive), onClick: function () { return setOtherTab(!otherTab); } },
                        getValueByTitle('tab3-title'),
                        isLAR ? React.createElement("img", { src: otherTab ? minus : plus, alt: 'openIcon' }) : React.createElement(FdsChevron, { direction: otherTab ? 'up' : 'down' })),
                    isLAR && otherTab && (React.createElement("div", { className: styles.tabbarDetails },
                        React.createElement("div", null,
                            React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('other1-value'), copy: getValueByTitle('other1-copy') },
                                React.createElement(PopupIcon, { letter: 'd', desktopDirection: DIRECTION.RIGHT, mobileDirection: DIRECTION.TOP, mobileWidth: 450, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                    React.createElement(DangerousHtml, { html: renderFootnote(isLAR ? 'c' : 'd', footnotes) }))),
                            React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('other2-value'), copy: getValueByTitle('other2-copy') })),
                        React.createElement("div", { className: styles.earnSectionRight },
                            React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('other3-value'), copy: getValueByTitle('other3-copy') })))),
                    !isLAR && otherTab && (React.createElement("div", { className: styles.tabbarDetails },
                        React.createElement("div", null,
                            React.createElement(EarnTile, { points: getValueByTitle('other1-value'), copy: getValueByTitle('other1-copy') }),
                            React.createElement(EarnTile, { points: getValueByTitle('other2-value'), copy: getValueByTitle('other2-copy') })),
                        React.createElement("div", { className: styles.earnSectionRight },
                            React.createElement(EarnTile, { points: getValueByTitle('other3-value'), copy: getValueByTitle('other3-copy') })))))))));
}
export default EarnPointsSectioMobile;
