import React, { useState } from 'react';
import { useContent } from '../../../hooks/use-content';
//////////////////////
////////////////////////////////////////////////////////
/////////
import { footies } from '../../footnotes/footnotes-fpr';
//////////
import styles from './earn-points-section.module.scss';
import cx from '../../../utils/classnames';
import common from '../../../utils/common.module.scss';
import utils from '../../../utils/utils.module.scss';
import PopupIcon, { DIRECTION } from '../../../components/common/popup-icons/popup-icons';
import { EarnSection } from '../earn-section/earn-section';
import ServiceHandler from '../../../services/service-handler';
import TabBar from '../../../components/common/tabbar/tabbar';
import Tab from '../../../components/common/tabbar/tab';
import { DangerousHtml } from '../../../components/common/dangerous-html/dangerous-html';
var TAB_STATES;
(function (TAB_STATES) {
    TAB_STATES["EARN_NEW"] = "new";
    TAB_STATES["EARN_VISA"] = "visa";
    TAB_STATES["EARN_OTHER"] = "other";
})(TAB_STATES || (TAB_STATES = {}));
function EarnPointsSection() {
    var _a;
    var cfg = ServiceHandler.ConfigService;
    var isLAR = cfg === null || cfg === void 0 ? void 0 : cfg.isLAR;
    var _b = useState(TAB_STATES.EARN_NEW), earnTab = _b[0], setEarnTab = _b[1];
    var getValueByTitle = useContent('v2/overview-tabbar')[0].getValueByTitle;
    ////////////////////////
    /////////////////////
    ////////////////////////
    //////////////////////////
    /////////////////////
    ////////////////////////////////
    ////////////////////
    ////
    ///////////
    var footnotes = {
        a: footies.MODEM1,
        b: footies.ACTIVATE_ACCOUNT,
        c: footies.TandC,
        d: footies.OTHER_LIMITATIONS,
        e: footies.TIER_LIMITATIONS,
    };
    ////////////
    return (React.createElement("div", { id: 'earn', className: styles.earn },
        React.createElement("div", null,
            React.createElement("div", { className: cx(common.h3, styles.earnTitle) }, getValueByTitle('title')),
            React.createElement("div", { className: styles.tabbar },
                React.createElement(TabBar, { setTab: function (id) { return setEarnTab(id); }, active: earnTab, ariaLabel: 'earning options' },
                    React.createElement(Tab, { id: TAB_STATES.EARN_NEW, className: styles.tab }, getValueByTitle('tab1-title').toUpperCase()),
                    React.createElement(Tab, { id: TAB_STATES.EARN_VISA, className: styles.tab }, getValueByTitle('tab2-title').toUpperCase()),
                    React.createElement(Tab, { id: TAB_STATES.EARN_OTHER, className: styles.tab }, getValueByTitle('tab3-title').toUpperCase()))),
            React.createElement("div", { className: cx(utils.twoColumn, isLAR ? styles.earnContainerLAR : styles.earnContainer) },
                earnTab == TAB_STATES.EARN_NEW && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: cx((_a = {}, _a[styles.earnText] = isLAR, _a)) },
                        React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('points1-value'), copy: getValueByTitle('points1-copy') },
                            React.createElement(PopupIcon, { letter: 'a', desktopDirection: DIRECTION.RIGHT, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: footnotes.a }))),
                        React.createElement("br", null),
                        React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('points2-value'), copy: getValueByTitle('points2-copy') },
                            React.createElement(PopupIcon, { letter: 'a', desktopDirection: DIRECTION.RIGHT, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: footnotes.a })))),
                    React.createElement("div", { className: "".concat(styles.earnSectionRight, " ").concat(isLAR && styles.earnText) },
                        React.createElement(EarnSection, { isLar: isLAR, className: isLAR ? styles.upTo22000LAR : styles.upTo22000, points: getValueByTitle('points4-value'), copy: getValueByTitle('points4-copy') },
                            React.createElement(PopupIcon, { letter: 'b', desktopDirection: DIRECTION.RIGHT, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: footnotes.b })))))),
                earnTab == TAB_STATES.EARN_VISA && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: isLAR && styles.earnText },
                        React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('visa1-value'), copy: getValueByTitle('visa1-copy') },
                            React.createElement(PopupIcon, { letter: 'c', desktopDirection: DIRECTION.RIGHT, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: footnotes.c }))),
                        React.createElement("br", null),
                        React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('visa2-value'), copy: getValueByTitle('visa2-copy') },
                            React.createElement(PopupIcon, { letter: 'c', desktopDirection: DIRECTION.RIGHT, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: footnotes.c })))),
                    React.createElement("div", { className: "".concat(styles.earnSectionRight, " ").concat(isLAR && styles.earnText) },
                        React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('visa3-value'), copy: getValueByTitle('visa3-copy') },
                            React.createElement(PopupIcon, { letter: 'c', desktopDirection: DIRECTION.RIGHT, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: footnotes.c }))),
                        React.createElement("br", null),
                        React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('visa4-value'), copy: getValueByTitle('visa4-copy') },
                            React.createElement(PopupIcon, { letter: 'c', desktopDirection: DIRECTION.RIGHT, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: footnotes.c })))))),
                earnTab == TAB_STATES.EARN_OTHER && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: isLAR && styles.earnText },
                        React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('other1-value'), copy: getValueByTitle('other1-copy') },
                            React.createElement(PopupIcon, { letter: 'd', desktopDirection: DIRECTION.RIGHT, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                                React.createElement(DangerousHtml, { html: isLAR ? '' : footnotes.d }))),
                        React.createElement("br", null),
                        React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('other2-value'), copy: getValueByTitle('other2-copy') })),
                    React.createElement("div", { className: "".concat(styles.earnSectionRight, " ").concat(isLAR && styles.earnText) },
                        React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('other3-value'), copy: getValueByTitle('other3-copy') }))))))));
}
export default EarnPointsSection;
